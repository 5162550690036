<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="90vh"
      transition="dialog-top-transition"
      @click:outside="closeAddContactModal(false)"
    >
      <!-- @click:outside="closeModalUpdateContact()"⁄ -->
      <v-card rounded="lg">
        <v-card-title primary-title class="justify-center pa-10">
          <div>
            <h2 class="black--text">
              Agregar Asesor
            </h2>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form ref="addContactForm" v-model="addContactFormValid">
            <v-row justify="space-between" align="center">
              <v-col cols="12" sm="6">
                <div class="mb-3">
                  <h3>
                    Nombre
                  </h3>
                </div>
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  :disabled="isLoadingAddContact"
                  outlined
                  placeholder="Nombre"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <div class="mb-3">
                  <h3>
                    Role
                  </h3>
                </div>
                <v-select
                  v-model="role"
                  placeholder="Elige un role"
                  :items="itemsRole"
                  item-text="text"
                  item-value="value"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row justify="space-between" align="center">
              <v-col cols="12" sm="6">
                <div class="mb-3">
                  <h3>
                    Correo
                  </h3>
                </div>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :disabled="isLoadingAddContact"
                  outlined
                  placeholder="Correo"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <!--  <h3>
                    Teléfono
                  </h3> -->
                <v-tooltip max-width="300px" color="black" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <h3>
                      Teléfono
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="primary"> mdi-help-circle </v-icon>
                      </v-btn>
                    </h3>
                  </template>

                  <v-row class="pa-4">
                    <v-col cols="12" class="ma-0 pa-0">
                      Registrar 10 dígitos.
                    </v-col>
                  </v-row>
                </v-tooltip>

                <v-text-field
                  v-model="phone"
                  :disabled="isLoadingAddContact"
                  outlined
                  placeholder="Teléfono"
                  required
                />
              </v-col>
            </v-row>
            <v-row justify="space-between" align="center">
              <v-col cols="12" sm="6">
                <div class="mb-3">
                  <h3>Contraseña <span class="password">(Temporal) </span></h3>
                </div>
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :disabled="isLoadingAddContact"
                  type="password"
                  outlined
                  placeholder="Contraseña"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <div class="mb-3">
                  <h3>
                    Confirmar contraseña
                    <span class="password">(Temporal) </span>
                  </h3>
                </div>
                <v-text-field
                  v-model="confirmPassword"
                  :rules="[confirmPasswordRules.required, confirmPasswordRule]"
                  :disabled="isLoadingAddContact"
                  outlined
                  type="password"
                  placeholder="Confirmar contraseña"
                  required
                />
              </v-col>
              <v-col class="pb-10" cols="12">
                <div class="warning--text">
                  <h4>
                    Por seguridad, recuerda avisar al nuevo asesor actualizar su
                    contraseña.
                  </h4>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <v-card class="mx-auto" elevation="0">
            <v-toolbar flat color="transparent">
              <v-toolbar-title>Proyecto Asignados</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-col v-if="!allSelected" cols="6">
                <v-text-field
                  ref="search"
                  v-model="search"
                  :disabled="isLoadingAddContact"
                  full-width
                  hide-details
                  label="Filtrar proyecto"
                  single-line
                ></v-text-field>
              </v-col>
            </v-toolbar>

            <v-container class="py-0">
              <v-row align="center" justify="start">
                <v-col v-if="selections.length === 0" class="pa-10 grey--text">
                  No has asignado ningun proyecto para este asesor
                </v-col>
                <v-col
                  v-else
                  v-for="(selection, i) in selections"
                  :key="selection.text"
                  class="shrink"
                >
                  <v-chip
                    :disabled="isLoadingAddContact"
                    close
                    @click:close="selectedRealEstate.splice(i, 1)"
                  >
                    {{ selection.name }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>

            <v-divider v-if="!allSelected"></v-divider>
            <div v-if="!allSelected" class="py-5 grey--text">
              Proyectos disponibles para Asignar
            </div>
            <v-list>
              <template v-for="item in availableRealEstate">
                <v-list-item
                  v-if="item && !selectedRealEstate.includes(item)"
                  :key="item._id"
                  :disabled="isLoadingAddContact"
                  @click="selectedRealEstate.push(item)"
                >
                  <v-list-item-avatar>
                    <v-icon :disabled="isLoadingAddContact"> mdi-plus</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
          <small class="red--text">{{ messageError }}</small>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12" sm="6">
            <v-btn
              class="mb-15 rounded-lg"
              color="primary"
              text
              :disabled="isLoadingAddContact"
              block
              large
              @click="closeAddContactModal(false)"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              class="mb-15 rounded-lg"
              color="primary"
              :disabled="isLoadingAddContact"
              :loading="isLoadingAddContact"
              block
              large
              @click="validateAddContactForm"
            >
              Agregar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    realEstate: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      contactToAdd: null,
      addContactFormValid: true,
      isLoadingAddContact: false,
      updatedData: null,
      messageError: "",
      statusUpdate: "",
      role: "",
      itemsRole: [
        { text: "Administrador", value: "admin" },
        { text: "Broker", value: "broker" }
      ],
      name: "",
      nameRules: [v => !!v || "El nombre es requerido"],
      phone: "",
      phoneRules: [
        v => !!v || "El teléfono es requerido",
        // v => v.length >= 10 || "The phone number is incorrect",
        // v => v.length <= 10 || "The phone number is incorrect",
        v =>
          Number.isInteger(Number(v)) || "El teléfono no puede contener letras"
      ],
      email: "",
      emailRules: [
        v => !!v || "E-mail es requerido",
        v => v === v.toLowerCase() || "El correo solo debe contener minúsculas",
        v => /.+@.+/.test(v) || "E-mail no valido"
      ],
      password: "",
      passwordRules: [
        v => !!v || "La contraseña es requerida",
        v => v.length >= 6 || "La contraseña debe tener mas de 6 caracteres"
      ],
      confirmPassword: "",
      confirmPasswordRules: {
        required: value => !!value || "Requerido"
      },
      search: "",
      selectedRealEstate: [],
      allLocation: []
    };
  },
  computed: {
    ...mapState({
      contactListSt: state => state.contacts.contactList
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", this.updatedData);
        }
      }
    },
    allSelected: {
      get: function() {
        var value_allSelected = false;
        value_allSelected =
          this.selectedRealEstate.length === this.realEstate.length;
        return value_allSelected;
      },
      set: function(newValue) {
        this.value_allSelected = newValue;
      }
    },
    availableRealEstate: {
      get: function() {
        var availableRealEstate_c;
        if (!this.search.toLowerCase()) {
          availableRealEstate_c = this.realEstate;
          return availableRealEstate_c;
        } else {
          return this.realEstate.filter(item => {
            if (item) {
              availableRealEstate_c =
                item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;

              return availableRealEstate_c;
            }
          });
        }
      },
      set: function(newValue) {
        this.availableRealEstate_c = newValue;
      }
    },
    selections: {
      get: function() {
        var selections_c = [];
        for (const selection of this.selectedRealEstate) {
          selections_c.push(selection);
        }
        return selections_c;
      },
      set: function(newvalue) {
        this.selections_c = newvalue;
      }
    }
  },
  watch: {
    selectedRealEstate() {
      this.search = "";
    }
  },
  methods: {
    ...mapActions({
      createUserStore: "contacts/createUser",
      updateContactByIdStore: "contacts/updateContactById",
      updateContactAllocationStore: "contacts/updateContactListAllocation",
      postCreateContactListLocationStore:
        "contacts/postCreateContactListLocation"
    }),
    setAllLocation(list) {
      this.allLocation = list;
    },
    confirmPasswordRule(v) {
      if (this.password === v) {
        return true;
      } else {
        return "Las contraseñas no coinciden";
      }
    },
    closeAddContactModal(val) {
      this.allSelected = false;
      this.selections = [];
      this.availableRealEstate = [];
      this.selectedRealEstate = [];
      this.messageError = "";
      this.show = val;
      this.$refs.addContactForm.resetValidation();
      this.name = "";
      this.email = "";
      this.phone = "";
      this.role = "";
    },
    showSnackBar() {
      this.$snotify.async(
        "Registrando Asesor",
        "Registrando asesor...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.closeSnackBar(resolve, reject);
            }, 500);
          })
      );
    },
    closeSnackBar(resolveNotify) {
      setTimeout(() => {
        resolveNotify({
          title: "Asesor registrado",
          body: "El asesor ha sido registrado correctamente.",
          config: {
            closeOnClick: true,
            titleMaxLength: 30,
            timeout: 1500
          }
        });
      }, 300);
    },

    validateAddContactForm() {
      //   this.AddContact();
      if (this.$refs.addContactForm.validate()) {
        // if (this.selectedRealEstate.length === 0) {
        //   this.messageError = "No puedes dejar un asesor sin desarollo.";
        // } else {
        this.AddContact();
        // }
      } else {
        this.messageError = "Por favor, verifica los errores.";
      }
    },
    AddContact() {
      this.isLoadingAddContact = true;
      const userToken = localStorage.getItem("token");
      const payload = JSON.parse(atob(userToken.split(".")[1]));
      let finalRealEstateAssing = [];
      for (let i = 0; i < this.selectedRealEstate.length; i++) {
        finalRealEstateAssing.push(this.selectedRealEstate[i]._id);
      }
      this.createUserStore({
        name: this.name,
        email: this.email,
        phone: this.phone,
        password: this.password,
        role: this.role,
        real_estate_group_id: payload.access_group
      })
        .then(res => {
          this.updateContact(res.User, finalRealEstateAssing);
        })
        .catch(() => {
          this.messageError = "Ocurrio un error, intentalo de nuevo.";
          this.isLoadingAddContact = false;
        });
    },
    updateContact(user, real) {
      this.contactToAdd = user;
      this.updateContactByIdStore({
        role: "admin",
        data: {
          _id: user.contact_id,
          table: this.role,
          role: "admin",
          name: this.name,
          email: this.email,
          phone: this.phone,
          real_estate_develop_id: real
        }
      })
        .then(res => {
          if (Object.prototype.hasOwnProperty.call(res.user, "contact_id")) {
            const self = this;
            self.createProject().then(() => {
              self.userProjectsIn().then(() => {
                self.updatedData = res;
                self.closeAddContactModal(false);
                self.isLoadingAddContact = false;
                this.showSnackBar();
              });
            });
          } else {
            this.messageError = "Ocurrio un error, intentalo de nuevo.";
            this.isLoadingAddContact = false;
          }
        })
        .catch(() => {
          this.messageError = "Ocurrio un error, intentalo de nuevo.";
          this.isLoadingAddContact = false;
        });
    },
    async createProject() {
      const self = this;
      this.isLoadingUpdateContact = true;
      for (let x = 0; x < this.selectedRealEstate.length; x++) {
        if (
          this.allLocation.filter(i => {
            if (
              i &&
              i.real_estate_develop_id !== undefined &&
              i.real_estate_develop_id !== ""
            ) {
              return (
                i.real_estate_develop_id === this.selectedRealEstate[x]._id
              );
            } else {
              return false;
            }
          }).length === 0
        ) {
          await this.postCreateContactListLocationStore({
            name: this.selectedRealEstate[x].name,
            realEstateDevelopId: this.selectedRealEstate[x]._id,
            contact_list: []
          }).then(res => {
            self.allLocation = res
              ? res.response.data.Allocation
              : self.allLocation;
          });
        }
      }
    },
    async userProjectsIn() {
      let tmpRealEstateAllId = [];
      for (let x = 0; x < this.realEstate.length; x++) {
        tmpRealEstateAllId.push(this.realEstate[x]._id);
      }

      let tmpRealEstateAssingSelected = [];
      for (let i = 0; i < this.selectedRealEstate.length; i++) {
        tmpRealEstateAssingSelected.push(this.selectedRealEstate[i]._id);
      }

      let tmpRealEstateAssingNoSelected = [];
      for (let i = 0; i < tmpRealEstateAllId.length; i++) {
        if (!tmpRealEstateAssingSelected.includes(tmpRealEstateAllId[i])) {
          tmpRealEstateAssingNoSelected.push(tmpRealEstateAllId[i]);
        }
      }

      let selected = [];

      for (let s = 0; s < this.realEstate.length; s++) {
        for (let n = 0; n < tmpRealEstateAssingSelected.length; n++) {
          if (this.realEstate[s]._id === tmpRealEstateAssingSelected[n]) {
            selected.push(this.realEstate[s]);
          }
        }
      }

      for (let i = 0; i < selected.length; i++) {
        for (let j = 0; j < this.allLocation.length; j++) {
          if (
            this.allLocation[j] &&
            this.allLocation[j].real_estate_develop_id !== undefined &&
            this.allLocation[j].real_estate_develop_id === selected[i]._id
          ) {
            if (
              this.allLocation[j].contact_list.filter(i => {
                return i.idContact === this.contactToAdd.contact_id;
              }).length === 0
            ) {
              let tmpList = this.allLocation[j].contact_list;
              tmpList.push({
                idContact: this.contactToAdd.contact_id,
                status: this.contactToAdd.status,
                status_assigned: "0"
              });
              this.updateContactAllLocations(this.allLocation[j], tmpList);
            }
          }
        }
      }
    },
    updateContactAllLocations(allLocation, contactList) {
      this.updateContactAllocationStore({
        idAllocation: allLocation._id,
        real_estate_group_id: allLocation.real_estate_group_id,
        name: allLocation.name,
        contact_list: contactList
      });
      self.isLoadingAddContact = false;
    }
  }
};
</script>

<style scoped>
.v-dialog {
  border-radius: 24px !important;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
.v-expansion-panel {
  box-shadow: none;
}
.password {
  font-weight: 500;
  font-size: 12px;
}
</style>
